import styled from "styled-components";

export const StyledOverviewWrapper = styled.section`
  margin-top: 68px;
`;

export const StyledOverviewContent = styled.div`
  padding: 0 149px;
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledOverviewDivider = styled.div`
  margin: 219px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before,
  &::after {
    content: "";
    display: block;
    height: 5px;
    min-width: 200px;
    background: ${({ theme }) => theme.colors.silver};
  }
  &::before {
    margin-right: 70px;
  }

  &::after {
    margin-left: 70px;
  }
  @media only screen and (max-width: 425px) {
    &::before {
      margin-right: 20px;
    }

    &::after {
      margin-left: 20px;
    }
  }
`;

export const StyledOverviewDividerIcon = styled.img``;

export const StyledOverviewTitle = styled.p`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 0 70px 0;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;
