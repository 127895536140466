import React from "react";

import Layout from "../components/layout/layout.component";
import Footer from "../sections/footer/footer.component";
import Contact from "../components/contact/contact.component";
import SubpageHero from "../components/subpageHero/subpageHero.component";
import WorkOverview from "../sections/subpageSections/realizationsSubpage/workOverview.component";
import SEO from "../components/seo.component";
import { LANG } from "../lang/lang";

const RealizacjePage = () => {
  return (
    <Layout>
      <SEO title={LANG.PL.realizationsSubpage.seoTitle} />
      <SubpageHero
        title={LANG.PL.realizationsSubpage.title}
        subTitle={LANG.PL.realizationsSubpage.subTitle}
      >
        <WorkOverview />
        <Contact />
        <Footer />
      </SubpageHero>
    </Layout>
  );
};

export default RealizacjePage;
