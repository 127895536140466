import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  StyledRealizationDetails,
  StyledRealizationImage,
  StyledRealizationParamBullet,
  StyledRealizationParamItem,
  StyledRealizationParamList,
  StyledRealizationParamTitle,
  StyledRealizationThunder,
  StyledRealizationTitle,
  StyledRealizationWrapper,
  StyledSliderBox,
  StyledSliderIcon,
  StyledSliderItem,
  StyledSliderOpenFullScreen,
} from "./realization.style";
import { StyledBoldTitle } from "../../../../utils/styledComponents/boldTitle.style";
import Popup from "../../../../components/popup/popup.component";
import Thunder from "../../../../images/thunderIcon.png";
import Bullet from "../../../../images/bullet.png";
import FullScreenIcon from "../../../../images/screen-full.svg";

type ImagesItem = {
  id: number;
  src: string;
};

type ParamsItem = {
  id: number;
  value: string;
};

type Params = {
  [name: string]: ParamsItem;
};

type RealizationPropsType = {
  city: string;
  value: string;
  paramTitle: string;
  images: ImagesItem[];
  params: Params;
};

const Realization = ({
  city,
  value,
  paramTitle,
  params,
  images,
}: RealizationPropsType): JSX.Element => {
  const [fullScreen, setFullScreen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const items = Object.entries(params).map((e) => e[1]);
  const parameters = items.map((el) => {
    return (
      <StyledRealizationParamItem key={el.id}>
        <StyledRealizationParamBullet src={Bullet} />
        {el.value}
      </StyledRealizationParamItem>
    );
  });

  const openFullScreen = (images) => {
    setCurrentImages((currentImages) => [...currentImages, images]);
    setFullScreen(true);
  };

  const closeFullScreen = () => {
    fullScreen && setFullScreen(false);
  };
  const renderImages = () => {
    return images.map((el) => {
      return (
        <StyledSliderItem key={el.id} active={fullScreen}>
          <StyledRealizationImage src={el.src} />
          <StyledSliderOpenFullScreen
            active={fullScreen}
            onClick={() => openFullScreen(images)}
          >
            <StyledSliderIcon src={FullScreenIcon} />
          </StyledSliderOpenFullScreen>
        </StyledSliderItem>
      );
    });
  };

  return (
    <StyledRealizationWrapper>
      <Popup
        images={currentImages[0]}
        show={fullScreen}
        clicked={closeFullScreen}
      ></Popup>
      <StyledSliderBox active={fullScreen}>
        <Carousel showArrows={fullScreen} showThumbs={false} showStatus={false}>
          {renderImages()}
        </Carousel>
      </StyledSliderBox>
      <StyledRealizationDetails>
        <StyledRealizationTitle>
          <StyledBoldTitle>{city}</StyledBoldTitle>
          <StyledRealizationThunder src={Thunder} />
          <StyledBoldTitle>{value}</StyledBoldTitle>
        </StyledRealizationTitle>
        <StyledRealizationParamTitle>{paramTitle}</StyledRealizationParamTitle>
        <StyledRealizationParamList>{parameters}</StyledRealizationParamList>
      </StyledRealizationDetails>
    </StyledRealizationWrapper>
  );
};

export default Realization;
