import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  StyledCloseFullScreen,
  StyledPopup,
  StyledSliderIcon,
  StyledSliderImage,
  StyledSliderItem,
} from "./popup.style";
import Backdrop from "../backdrop/backdrop.component";
import CloseIcon from "../../images/close.svg";

type ImageItem = {
  id: number;
  src: string;
};

type PopupPropTypes = {
  show: boolean;
  clicked: () => void;
  images: ImageItem[];
};

const Popup = ({ show, clicked, images }: PopupPropTypes): JSX.Element => {
  const renderimages = () =>
    images.map((el) => (
      <StyledSliderItem key={el.id}>
        <StyledSliderImage src={el.src} />
      </StyledSliderItem>
    ));
  return show ? (
    <>
      <Backdrop show={show} clicked={clicked} />
      <StyledPopup>
        <Carousel
          emulateTouch={true}
          showArrows={true}
          autoPlay={false}
          showThumbs={false}
          showStatus={false}
        >
          {renderimages()}
        </Carousel>
        <StyledCloseFullScreen active={show} onClick={clicked}>
          <StyledSliderIcon src={CloseIcon} />
        </StyledCloseFullScreen>
      </StyledPopup>
    </>
  ) : null;
};

export default Popup;
