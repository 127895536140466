import React from "react";

import { StyledBackdrop } from "./backdrop.style";

type BackdropPropTypes = {
  show: boolean;
  clicked: () => void;
};

const Backdrop = ({ show, clicked }: BackdropPropTypes): JSX.Element => {
  return show ? <StyledBackdrop onClick={clicked}></StyledBackdrop> : null;
};

export default Backdrop;
