import styled from "styled-components";

export const StyledBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.popupBg};
`;
