import styled from 'styled-components';

export const StyledRealizationWrapper = styled.div`
  height: auto;
  max-width: 1364px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 70px;
  padding: 60px 0px 60px 60px;
  display: flex;
  @media only screen and (max-width: 1520px) {
    max-width: 70%;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 70px auto;
  }
  @media only screen and (max-width: 1024px) {
    padding: 30px;
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    height: fit-content;
  }
  @media only screen and (max-width: 425px) {
    padding: 30px 10px;
  }
`;

export const StyledSliderBox = styled.div`
  max-width: 530px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 425px) {
    max-width: 300px;
    height: 250px;
  }
`;

export const StyledSliderItem = styled.div`
  max-width: 530px;
  height: 400px;
  position: relative;
  @media only screen and (max-width: 1024px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 425px) {
    max-width: 300px;
    height: 250px;
  }
`;
export const StyledSliderOpenFullScreen = styled.button`
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  right: 3%;
  bottom: 3%;
  transition: transform 0.2s;
  display: ${({ active }) => (active ? 'none' : 'block')};
  filter: invert(100%) sepia(8%) saturate(7494%) hue-rotate(188deg) brightness(107%) contrast(96%);
  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledSliderIcon = styled.img``;

export const StyledRealizationImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 20px;
`;

export const StyledRealizationThunder = styled.img`
  width: 22px;
  height: 34px;
  margin: 0 30px;
  @media only screen and (max-width: 425px) {
    margin: 10px 0;
  }
`;

export const StyledRealizationDetails = styled.div`
  max-width: 794px;
  padding: 15px 20px 0px 80px;
  @media only screen and (max-width: 1520px) {
    padding: 40px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 60px 20px 20px 20px;
  }
  @media only screen and (max-width: 425px) {
    padding: 60px 5px 20px 5px;
  }
`;

export const StyledRealizationTitle = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const StyledRealizationParamTitle = styled.p`
  color: ${({ theme }) => theme.colors.black_2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 10px 0 40px 0;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin: 40px 0;
    text-align: center;
  }
`;

export const StyledRealizationParamList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  @media only screen and (max-width: 425px) {
    padding: 0 15px;
  }
`;

export const StyledRealizationParamBullet = styled.img`
  margin-right: 15px;
  @media only screen and (max-width: 425px) {
    margin-right: 5px;
    width: 8px;
    height: 8px;
  }
`;

export const StyledRealizationParamItem = styled.li`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black_3};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: 10px;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
  @media only screen and (max-width: 375px) {
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;
