import styled from "styled-components";

export const StyledPopup = styled.div`
  position: fixed;
  z-index: 9999;
  background: transparent;
  width: 80vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10%;
  top: 15%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  @media only screen and (max-width: 768px) {
    width: 95vw;
    height: 60vh;
    left: 2.5%;
    top: 20%;
  }
`;

export const StyledCloseFullScreen = styled.button`
  cursor: pointer;
  position: absolute;
  background: none;
  border: none;
  right: 2%;
  top: 2%;
  display: ${({ active }) => (active ? "block" : "none")};
  @media only screen and (max-width: 1024px) {
    transform: scale(0.5);
    right: 0%;
    top: 0%;
  }
  @media only screen and (max-width: 768px) {
    right: 3%;
  }
`;
export const StyledSliderIcon = styled.img``;

export const StyledSliderImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;

export const StyledSliderItem = styled.div`
  width: auto;
  max-width: 70vw;
  height: 65vh;
  position: relative;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    max-width: 85vw;
    height: 60vh;
  }
`;
