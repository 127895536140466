import React from 'react';

import {
  StyledOverviewContent,
  StyledOverviewDivider,
  StyledOverviewDividerIcon,
  StyledOverviewTitle,
  StyledOverviewWrapper,
} from './workOverview.style';
import Realization from './realization/realization.component';
import { StyledCenter } from '../../../utils/styledComponents/center.style';
import { LANG } from '../../../lang/lang';
import DividerIcon from '../../../images/smallVoltspotIcon.png';
import Poznan_1 from '../../../images/Poznan_1.jpg';
import Poznan_2 from '../../../images/Poznan_2.jpg';
import Nowe_1 from '../../../images/noweBudki_1.jpg';
import Nowe_2 from '../../../images/noweBudki_2.jpg';
import Nowe_3 from '../../../images/noweBudki_3.jpg';
import Nowe_4 from '../../../images/noweBudki_4.jpg';
import Zielona_1 from '../../../images/Zielona_1.jpg';
import Zielona_2 from '../../../images/Zielona_2.jpg';
import Zielona_3 from '../../../images/Zielona_3.jpg';
import Inowroclaw_1 from '../../../images/Inowroclaw_1.jpg';
import Inowroclaw_2 from '../../../images/Inowroclaw_2.jpg';
import Inowroclaw_3 from '../../../images/Inowroclaw_3.jpg';
import Inowroclaw_4 from '../../../images/Inowroclaw_4.jpg';
import Inowroclaw_5 from '../../../images/Inowroclaw_5.jpg';
import Tupadly_1 from '../../../images/Tupadly_1.jpg';
import Tupadly_2 from '../../../images/Tupadly_2.jpg';
import Tupadly_3 from '../../../images/Tupadly_3.jpg';
import Kikol_1 from '../../../images/Kikol_1.jpeg';
import Kikol_2 from '../../../images/Kikol_2.jpeg';
import Kikol_3 from '../../../images/Kikol_3.jpeg';
import Kikol_4 from '../../../images/Kikol_4.jpeg';
import Kostrzyn_1 from '../../../images/Kostrzyn-1.jpg';
import Kostrzyn_2 from '../../../images/Kostrzyn-2.jpg';
import Kostrzyn_3 from '../../../images/Kostrzyn-3.jpg';
import Kolo1_1 from '../../../images/Kolo1-1.jpg';
import Kolo1_2 from '../../../images/Kolo1-2.jpg';
import Kolo1_3 from '../../../images/Kolo1-3.jpg';
import Inowroclaw1_1 from '../../../images/Inowroclaw1-1.jpg';
import Inowroclaw1_2 from '../../../images/Inowroclaw1-2.jpg';
import Broniewo_1 from '../../../images/Broniewo_1.jpeg';
import Broniewo_2 from '../../../images/Broniewo_2.jpeg';
import Inowroclaw2_1 from '../../../images/Inowroclaw2_1.jpeg';
import Poznan1_1 from '../../../images/Poznan1_1.png';
import Boguslawice_1 from '../../../images/Boguslawice_1.jpeg';
import Boguslawice_2 from '../../../images/Boguslawice_2.jpeg';
import Sady_1 from '../../../images/Sady_1.jpeg';
import Kolo2_1 from '../../../images/Kolo2_1.jpeg';
import Kolo2_2 from '../../../images/Kolo2_2.jpeg';
import Kolo2_3 from '../../../images/Kolo2_3.jpeg';
import StrzelceKrajenskie1_1 from '../../../images/StrzelceKrajenskie1_1.jpeg';
import Lusowo_1 from '../../../images/Lusowo_1.jpeg';
import Slawecin_1 from '../../../images/Slawecin_1.jpeg';
import Kolo3_1 from '../../../images/Kolo3_1.jpeg';
import StrzelceKrajenskie2_1 from '../../../images/StrzelceKrajenskie2_1.jpeg';
import StrzelceKrajenskie2_2 from '../../../images/StrzelceKrajenskie2_2.jpeg';
import Inowroclaw3_1 from '../../../images/Inowroclaw3_1.jpeg';
import Inowroclaw3_2 from '../../../images/Inowroclaw3_2.jpeg';
import Kolo4_1 from '../../../images/Kolo4_1.jpeg';
import Szczytniki_1 from '../../../images/Szczytniki_1.jpeg';
import Szczytniki_2 from '../../../images/Szczytniki_2.jpeg';
import Inowroclaw4_1 from '../../../images/Inowroclaw4_1.jpeg';

const WorkOverview = (): JSX.Element => {
  const items = Object.entries(LANG.PL.realizationsSubpage.realizations).map((e) => e[1]);
  const images = [
    [
      {
        id: 1,
        src: Kikol_1,
      },
      {
        id: 2,
        src: Kikol_2,
      },
      {
        id: 3,
        src: Kikol_3,
      },
      {
        id: 4,
        src: Kikol_4,
      },
    ],
    [
      {
        id: 1,
        src: Kostrzyn_1,
      },
      {
        id: 2,
        src: Kostrzyn_2,
      },
      {
        id: 3,
        src: Kostrzyn_3,
      },
    ],
    [
      {
        id: 1,
        src: Kolo1_1,
      },
      {
        id: 2,
        src: Kolo1_2,
      },
      {
        id: 3,
        src: Kolo1_3,
      },
    ],
    [
      {
        id: 1,
        src: Inowroclaw1_1,
      },
      {
        id: 2,
        src: Inowroclaw1_2,
      },
    ],
    [
      {
        id: 1,
        src: Broniewo_1,
      },
      {
        id: 2,
        src: Broniewo_2,
      },
    ],
    [
      {
        id: 1,
        src: Inowroclaw2_1,
      },
    ],
    [
      {
        id: 1,
        src: Poznan1_1,
      },
    ],
    [
      {
        id: 1,
        src: Boguslawice_1,
      },
      {
        id: 2,
        src: Boguslawice_2,
      },
    ],
    [
      {
        id: 1,
        src: Sady_1,
      },
    ],
    [
      {
        id: 1,
        src: Kolo2_1,
      },
      {
        id: 2,
        src: Kolo2_2,
      },
      {
        id: 3,
        src: Kolo2_3,
      },
    ],
    [
      {
        id: 1,
        src: StrzelceKrajenskie1_1,
      },
    ],
    [
      {
        id: 1,
        src: Lusowo_1,
      },
    ],
    [
      {
        id: 1,
        src: Slawecin_1,
      },
    ],
    [
      {
        id: 1,
        src: Kolo3_1,
      },
    ],
    [
      {
        id: 1,
        src: StrzelceKrajenskie2_1,
      },
      {
        id: 2,
        src: StrzelceKrajenskie2_2,
      },
    ],
    [
      {
        id: 1,
        src: Inowroclaw3_1,
      },
      {
        id: 2,
        src: Inowroclaw3_2,
      },
    ],
    [
      {
        id: 1,
        src: Kolo4_1,
      },
    ],
    [
      {
        id: 1,
        src: Szczytniki_1,
      },
      {
        id: 2,
        src: Szczytniki_2,
      },
    ],
    [
      {
        id: 1,
        src: Inowroclaw4_1,
      },
    ],
    [
      {
        id: 1,
        src: Poznan_1,
      },
      {
        id: 2,
        src: Poznan_2,
      },
    ],
    [
      {
        id: 1,
        src: Nowe_1,
      },
      {
        id: 2,
        src: Nowe_2,
      },
      {
        id: 3,
        src: Nowe_3,
      },
      {
        id: 4,
        src: Nowe_4,
      },
    ],
    [
      {
        id: 1,
        src: Zielona_1,
      },
      {
        id: 2,
        src: Zielona_2,
      },
      {
        id: 3,
        src: Zielona_3,
      },
    ],
    [
      {
        id: 1,
        src: Inowroclaw_1,
      },
      {
        id: 2,
        src: Inowroclaw_2,
      },
      {
        id: 3,
        src: Inowroclaw_3,
      },
      {
        id: 4,
        src: Inowroclaw_4,
      },
      {
        id: 5,
        src: Inowroclaw_5,
      },
    ],
    [
      {
        id: 1,
        src: Tupadly_1,
      },
      {
        id: 2,
        src: Tupadly_2,
      },
      {
        id: 3,
        src: Tupadly_3,
      },
    ],
  ];
  const realizations = items.map((el) => {
    return (
      <Realization
        key={el.id}
        city={el.city}
        value={el.value}
        paramTitle={el.parametersTitle}
        params={el.parameters}
        images={images[el.id]}
      />
    );
  });
  return (
    <StyledOverviewWrapper>
      <StyledCenter>
        <StyledOverviewContent>
          <StyledOverviewTitle>{LANG.PL.realizationsSubpage.realizationsSectionTitle}</StyledOverviewTitle>
          {realizations}
        </StyledOverviewContent>

        <StyledOverviewDivider>
          <StyledOverviewDividerIcon src={DividerIcon} />
        </StyledOverviewDivider>
      </StyledCenter>
    </StyledOverviewWrapper>
  );
};

export default WorkOverview;
